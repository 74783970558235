var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"3180368843a17880f3e4d3a2ecf7abbf1c299d0d"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from '@sentry/nextjs';

import { getSentryEnvironment } from './utils/sentryUtils';

Sentry.init({
  dsn: 'https://05ca950e9fc6f773a85e215b01f12fb3@o4505861414191104.ingest.us.sentry.io/4508129473658880',
  environment: getSentryEnvironment(),

  // Add optional integrations for additional features
  integrations: [Sentry.replayIntegration(), Sentry.browserTracingIntegration()],

  tracesSampleRate: getSentryEnvironment() === 'production' ? 1 : 0,
  tracePropagationTargets: ['localhost', /^\//, /^http.+\.medworld.(site|com)/],

  replaysSessionSampleRate: 0,

  // Define how likely Replay events are sampled when an error occurs.
  replaysOnErrorSampleRate: getSentryEnvironment() === 'production' ? 1 : 0,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
});

Sentry.setTag('source', 'client');
